import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import DashboardArt from "../views/DashboardArt.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import addNewResturant from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import AddAccountDelivaryManeger from "../views/addDeliveryManager.vue";
import users from "../views/users.vue";


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard-default",
    name: "Ads",
    component: Dashboard,
  },
  {
    path: "/users",
    name: "users",
    component: users,
  },
  {
    path: "/DashboardArt",
    name: "DashboardArt",
    component: DashboardArt,
  },
  {
    path: "/tables",
    name: "Resturents",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/addNewResturant",
    name: "addNewResturant",
    component: addNewResturant,
  },
  {
    path: "/AddAccountDelivaryManeger",
    name: "AddAccountDelivaryManeger",
    component: AddAccountDelivaryManeger,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
