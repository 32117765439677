import { ref } from 'vue'


class UserData {
  constructor(blocked_until,cancel_count, created_at, email, email_verified_at, id, name, phone, status, updated_at) {
    this.blocked_until = blocked_until;
    this.cancel_count = cancel_count;
    this.created_at = created_at;
    this.email = email;
    this.email_verified_at = email_verified_at;
    this.id = id;
    this.name = name;
    this.phone = phone;
    this.status = status;
    this.updated_at = updated_at;
    this.name = name;

  }

}



class UserClass {
  constructor(token, blocked_until,cancel_count, created_at, email, email_verified_at, id, name, phone, status, updated_at) {
    this.token = token;
    this.userdata = new UserData(blocked_until,cancel_count, created_at, email, email_verified_at, id, name, phone, status, updated_at);
  }
  get_data_user_from_login(token, blocked_until,cancel_count, created_at, email, email_verified_at, id, name, phone, status, updated_at){
    this.token = token;
    this.userdata = new UserData(blocked_until,cancel_count, created_at, email, email_verified_at, id, name, phone, status, updated_at);
    return this;
  }
}






let userLog = new UserClass();


function save (userLogfromlog) {
  userLog = userLogfromlog;

}

function test () {
  console.log("userAllData 3");
  console.log(userLog);
}



let loginBool = false;
function saveLoginBool (loginBoolin) {
  loginBool = loginBoolin;
  console.log("saveLoginBool");
  console.log(loginBool);
}



async function reject(id){
  const axios = require('axios');
  let config2 = {
  method: 'post',
  maxBodyLength: Infinity,
  url: BaseURL + 'Decline_to_advertise\\'+ id ,
  headers: { 
    'Authorization': 'Bearer '+userLog.token
  }
};
  const response = await axios.request(config2);
  console.log(response);
};


async function accept(id){
const axios = require('axios');
  let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: BaseURL + 'AgreeAdvertisement\\'+ id ,
  headers: { 
    'Authorization': 'Bearer '+userLog.token
  }
};
  const response = await axios.request(config);
  console.log(response);

};


class FCM{
  constructor(token){
    this.fcm_token = token;
  }
}
let fcm = new FCM('');
const get_fcm = () => {return fcm.fcm_token;}
const set_fcm = (token) => {fcm = new FCM(token);}




const deleteUserDataLog = () =>{
  userLog = new UserClass(); 
}




let list_advertisement_to_not_get_detail_again = ref([]);

const get_list_advertisement_to_not_get_detail_again = () =>{ 
  return list_advertisement_to_not_get_detail_again.value};

const set_list_advertisement_to_not_get_detail_again = (one_advr) =>{ 
  list_advertisement_to_not_get_detail_again.value.push(one_advr)};



const imageURL= 'https://5delivery.store/DeliveryApp/public/upload/';
const BaseURL = 'https://5delivery.store/DeliveryApp/public/api/auth/';
let token = userLog.token;


export{UserClass, save, test, BaseURL,loginBool,saveLoginBool, userLog, token,
   accept, reject, imageURL,
    deleteUserDataLog,
    set_fcm, get_fcm,
    get_list_advertisement_to_not_get_detail_again,
    set_list_advertisement_to_not_get_detail_again
  }
