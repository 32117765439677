<script setup>
//import { onBeforeUnmount, onBeforeMount } from "vue";
//import { useStore } from "vuex";

// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { ref } from "vue";
import { BaseURL, userLog} from './DATA/login'

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';





const owner_name = ref("");
const resturent_name = ref("");
const resturent_address = ref("");
const email = ref("");
const resturent_phone = ref("");
const password = ref("");
const owner_phone = ref("");
const password_confirmation = ref("");

const addbool = ref(false);
const is_added = ref(false);
const is_not_added = ref(false);
const addMessege = ref("");

let errorList = []
//const addbool = ref(false);

function convertJsonErrorsToList(jsonString) {
  try {
    // Parse the JSON string to avoid potential errors
    const jsonData = JSON.parse(jsonString);

    // Create an empty list to store the error messages
    const errorList = [];

    // Iterate through each key-value pair in the parsed JSON object
    for (const [key, value] of Object.entries(jsonData)) {
      // Extract the error message (assuming the first element in the value array)
      const errorMessage = value[0];

      // Create a formatted error message string
      const formattedError = `${key}: ${errorMessage}`;

      // Append the formatted error message to the list
      errorList.push(formattedError);
    }

    return errorList;
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return []; // Return an empty list if parsing fails
  }
}

// Example usage
//const jsonString = '{"owner_name":["The owner name field is required."],"resturent_address":["The resturent address field is required."],"email":["The email field is required."],"password":["The password confirmation does not match."],"owner_phone":["The owner phone field is required."],"resturent_phone":["The resturent phone field is required."]}';






const addFunction =  () => {
const axios = require('axios');
const FormData = require('form-data');
let data = new FormData();
data.append('owner_name', owner_name.value);
data.append('resturent_name', resturent_name.value);
data.append('resturent_address', resturent_address.value);
data.append('email', email.value);
data.append('password', password.value);
data.append('owner_phone', owner_phone.value);
data.append('resturent_phone', resturent_phone.value);
data.append('password_confirmation', password_confirmation.value);


let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: BaseURL+'AddAccountResturent',
  headers: { 
    'Authorization': 'Bearer '+userLog.token, 
 
  },
  data : data
};

axios.request(config)
.then((response) => {
  is_added.value = true;
  addMessege.value  = "->"+response.data.message
  addbool.value = true;
  is_not_added.value = false;
  addMessege.value  = "->"+response.data.message
  addbool.value = true;
  is_not_added.value = false;

  createUserWithEmailAndPassword(getAuth(),email.value,password.value)
  .then((data) => {
    toast(response.data.message+" : "+data, {
    "theme": "colored",
    "type": "success",
    "position": "top-left",
    "autoClose": 3000,
  });
  })
  .catch((error) =>{
    toast(error, {
      "theme": "colored",
      "type": "error",
      "autoClose": 3000,
    });
  });
  toast(response.data.message, {
    "theme": "colored",
    "type": "success",
    "position": "top-left",
    "autoClose": 3000,
  });
})
.catch((error) => {
  console.log(error);
  //console.log(error)
  console.log(error.response.statusText)
  is_not_added.value = true;
  is_added.value = false;
  addMessege.value  = "Don't Add this Resturent Becuase :   "+error.response.statusText
  toast(addMessege.value, {
    "theme": "colored",
    "type": "error",
    "autoClose": 3000,
  })
  errorList = convertJsonErrorsToList(error.response.data);
  for (const element of errorList) {
    console.log(element);
    toast(element, {
    "theme": "colored",
    "position": "bottom-right",
    "type": "warning",
    "autoClose": 4000,
    "transition": "flip"
  })
  }
  console.log(addMessege.value)
  
});
};

</script>
<template>
  <div>
  
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
       :style="{backgroundImage: 'url(\'' + require('@/assets/img/admin/addResturant.jpg') + '\')'}"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h2 class="text-white mb-2 mt-5">ADD NEW RESTUENT!</h2>
            <p class="text-lead text-white" v-if="addbool">
              Thank you....
            </p>
            <p class="text-lead text-white" v-else>
            Create a new resturent account for new resturent in the System.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="!addbool">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-8 col-lg-10 col-md-14 mx-auto">
          <div class="card z-index-0">
            <div class="card-body">
                <argon-input
                  id="owner_name"
                  type="text"
                  placeholder="Owner Name"
                  aria-label="Owner Name"
                  v-model="owner_name"
                />
                <argon-input
                  id="resturent_name"
                  type="text"
                  placeholder="Resturent Name"
                  aria-label="Resturent Name"
                  v-model="resturent_name"
                />
                <argon-input
                  id="resturent_address"
                  type="text"
                  placeholder="Resturent Address"
                  aria-label="Resturent Address"
                  v-model="resturent_address"
                />
                <argon-input
                  id="email"
                  type="email"
                  placeholder="Email"
                  aria-label="Email"
                  v-model="email"
                />
                <argon-input
                  id="owner_phone"
                  type="text"
                  placeholder="Owner Phone"
                  aria-label="Owner Phone"
                  v-model="owner_phone"
                />
                <argon-input
                  id="resturent_phone"
                  type="text"
                  placeholder="Resturent Phone"
                  aria-label="Resturent Phone"
                  v-model="resturent_phone"
                />
                <argon-input
                  id="password"
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  v-model="password"
                />
                <argon-input
                  id="password_confirmation"
                  type="password"
                  placeholder="Password Confirmation"
                  aria-label="Password Confirmation"
                  v-model="password_confirmation"
                />
                  <div class="text-center"  @click="addFunction">
                  <argon-button
                    fullWidth
                    color="warning"
                    variant="gradient"
                    class="my-4 mb-2"
                    >Add Resturent</argon-button
                  >
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </main>
  
  
  <app-footer />
</div>
</template>

<style>
.check-mark-container {
  position: fixed;
  bottom: 30%;
  right: 50%;
  display: inline-block;
}
.check-mark {
  width: 20px;
  height: 20px;
  background-color: rgb(148, 175, 162);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: checkmark-rotate 1s linear infinite;
}

@keyframes checkmark-rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.check-mark.active {
  background-color: green;
  animation: checkmark-grow 0.5s ease-in-out;
}

@keyframes checkmark-grow {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

.check-mark.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: green;
  animation: checkmark-rotate 1s linear infinite;
  opacity: 0.5;
}
</style>
