<script setup>
import { BaseURL, userLog } from "../DATA/login";
import { ref, /*onMounted,*/ onMounted } from "vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
let list = ref([]);
const list_restaurants__ = ref([]);

const axios = require("axios");
let config = {
  method: "get",
  maxBodyLength: Infinity,
  url: BaseURL + "getOwner_resturent",
  headers: {
    Authorization: "Bearer " + userLog.token,
  },
};

const fetchData = async () => {
  try {
    const response = await axios.request(config);
    list_restaurants__.value = response.data.Owner_resturents;

    for (let i = 0; i < list_restaurants__.value.length; i++) {
      let active;
      let open;
      if (list_restaurants__.value[i].status == "now_open") {
        active = "badge badge-sm bg-gradient-success";
        open = "open";
      } else {
        active = "badge badge-sm bg-gradient-secondary";
        open = "close";
      }

      let one_resturant = {
        id: list_restaurants__.value[i].id,
        owner_name: list_restaurants__.value[i].owner_name,
        resturent_name: list_restaurants__.value[i].resturent_name,
        owner_phone: list_restaurants__.value[i].owner_phone,
        resturent_phone: list_restaurants__.value[i].resturent_phone,
        status: list_restaurants__.value[i].status,
        active: active,
        open: open,
      };
      list.value.push(one_resturant);
    }
  } catch (error) {
    toast("Error when get Resturent:  Try Again...", {
      "theme": "colored",
      "type": "warning",
      "autoClose": 1000,
    })
  }
};

onMounted(() => {
  fetchData();
});
</script>

<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Resturents Status</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Resturent Info
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Owner Info
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="list.length > 0">
              <tr v-for="restaurant in list" :key="restaurant.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ restaurant.resturent_name }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ restaurant.resturent_phone }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ restaurant.owner_name }}</p>
                  <p class="text-xs text-secondary mb-0">{{ restaurant.owner_phone }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span :class="restaurant.active">{{ restaurant.open }}</span>
                </td>
              </tr>
            </template>
            <template v-else>
              <div class="loader"></div>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(251, 99, 64);
  border-bottom: 4px solid rgb(251, 99, 64);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
