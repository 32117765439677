<script setup>
import MiniStatistics from "../examples/Cards/MiniStatistics.vue";
import GradientLineChart from "../examples/Charts/GradientLineChart.vue";
//import GradientLineChart2 from "../examples/Charts/GradientLineChart2.vue";


import {BaseURL, userLog} from './DATA/login'
import { ref, /*onMounted,*/ onMounted } from 'vue'

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';



//import im from 'C:\\Users\\Lenovo\\Downloads\\noData.png';
//import im1 from 'C:\\xampp\\tmp\\php513D.tmp';



let list_res = ref([]);
const list_restaurants__ = ref([]);
const getResturent = async() =>{
  try {
    const axios = require("axios");
    
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: BaseURL + "getOwner_resturent",
      headers: {
        Authorization: "Bearer " + userLog.token,
      },
    };
    const response = await axios.request(config);
    list_restaurants__.value = response.data.Owner_resturents;

    for (let i = 0; i < list_restaurants__.value.length; i++) {
      let one_resturant = {
        id: list_restaurants__.value[i].id,
        resturent_name: list_restaurants__.value[i].resturent_name,
        isSelected: false,
      };
      list_res.value.push(one_resturant);
      list_res.value[0].isSelected = true;
      
    }
  
  } catch (error) {
    toast("Error when get resturent:  Try Again...", {
      "theme": "colored",
      "type": "warning",
      "autoClose": 1000,
    })
  }

}



let listm = ref([]);
const list_salesm = ref([]);
let monthly_Sales = ref(0);
let monthly_Delivery = ref(0);
let monthly_Total = ref(0);

let loading_m = ref(false);
let noData_m = ref(false);



let list = ref([]);
const list_sales = ref([]);
let Daily_Sales = ref(0);
let Daily_Delivery = ref(0);
let Daily_Total = ref(0);

let loading_d = ref(false);
let noData_d = ref(false);



let listy = ref([]);
const list_salesy = ref([]);
let yearly_Sales = ref(0);
let yearly_Delivery = ref(0);
let yearly_Total = ref(0);

let loading_y = ref(false);
let noData_y = ref(false);

let yearCollection = ref([]);
//let yearCollection2 = ref([]);
let yearDataBool = ref(false)
//year months:
let Jan = ref(0);
let Feb = ref(0);
let Mar = ref(0);
let Apr = ref(0);
let May = ref(0);
let Jun = ref(0);
let Jul = ref(0);
let Aug = ref(0);
let Sep = ref(0);
let Oct = ref(0);
let Nov = ref(0);
let Dec = ref(0);



let monthCollection = ref([]);
//let yearCollection2 = ref([]);
let monthDataBool = ref(false)
let m1 = ref(0);
let m2 = ref(0);
let m3 = ref(0);
let m4 = ref(0);
let m5 = ref(0);
let m6 = ref(0);
let m7 = ref(0);
let m8 = ref(0);
let m9 = ref(0);
let m10 = ref(0);
let m11 = ref(0);
let m12 = ref(0);
let m13 = ref(0);
let m14 = ref(0);
let m15 = ref(0);
let m16 = ref(0);
let m17 = ref(0);
let m18 = ref(0);
let m19 = ref(0);
let m20 = ref(0);
let m21 = ref(0);
let m22 = ref(0);
let m23 = ref(0);
let m24 = ref(0);
let m25 = ref(0);
let m26 = ref(0);
let m27 = ref(0);
let m28 = ref(0);
let m29 = ref(0);
let m30 = ref(0);
let m31 = ref(0);

let towmonthCollection = ref([]);
//let yearCollection2 = ref([]);
let towmonthDataBool = ref(true);

let mc1 = ref(0);
let mc2 = ref(0);
let mc3 = ref(0);
let mc4 = ref(0);
let mc5 = ref(0);
let mc6 = ref(0);
let mc7 = ref(0);
let mc8 = ref(0);
let mc9 = ref(0);
let mc10 = ref(0);
let mc11 = ref(0);
let mc12 = ref(0);
let mc13 = ref(0);
let mc14 = ref(0);
let mc15 = ref(0);
let mc16 = ref(0);
let mc17 = ref(0);
let mc18 = ref(0);
let mc19 = ref(0);
let mc20 = ref(0);
let mc21 = ref(0);
let mc22 = ref(0);
let mc23 = ref(0);
let mc24 = ref(0);
let mc25 = ref(0);
let mc26 = ref(0);
let mc27 = ref(0);
let mc28 = ref(0);
let mc29 = ref(0);
let mc30 = ref(0);
let mc31 = ref(0);

let mp1 = ref(0);
let mp2 = ref(0);
let mp3 = ref(0);
let mp4 = ref(0);
let mp5 = ref(0);
let mp6 = ref(0);
let mp7 = ref(0);
let mp8 = ref(0);
let mp9 = ref(0);
let mp10 = ref(0);
let mp11 = ref(0);
let mp12 = ref(0);
let mp13 = ref(0);
let mp14 = ref(0);
let mp15 = ref(0);
let mp16 = ref(0);
let mp17 = ref(0);
let mp18 = ref(0);
let mp19 = ref(0);
let mp20 = ref(0);
let mp21 = ref(0);
let mp22 = ref(0);
let mp23 = ref(0);
let mp24 = ref(0);
let mp25 = ref(0);
let mp26 = ref(0);
let mp27 = ref(0);
let mp28 = ref(0);
let mp29 = ref(0);
let mp30 = ref(0);
let mp31 = ref(0);


let weekCollection = ref([]);
//let yearCollection2 = ref([]);
let weekDataBool = ref(false)
let weekD1 = ref(0);
let weekD2 = ref(0);
let weekD3 = ref(0);
let weekD4 = ref(0);
let weekD5 = ref(0);
let weekD6 = ref(0);
let weekD7 = ref(0);

const axios = require('axios');
let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: BaseURL + 'getAllBearenDaily',
  headers: { 
    'Authorization': 'Bearer '+userLog.token
  }
};

let config2 = {
  method: 'get',
  maxBodyLength: Infinity,
  url: BaseURL + 'getAllBearenMonthly',
  headers: { 
    'Authorization': 'Bearer '+userLog.token
  }
};


let config3 = {
  method: 'get',
  maxBodyLength: Infinity,
  url: BaseURL + 'getAllBearenYearly',
  headers: { 
    'Authorization': 'Bearer '+userLog.token
  }
};




const fetchDatayearly = async () => {
  try {
    const response = await axios.request(config3);
    list_salesy.value = response.data
    //console.log("list_Advertisements");
    console.log(list_salesy.value);
    if (list_salesy.value.length > 0) {
      loading_y.value = true;
    }
    if (list_salesy.value.length === 0) {
      toast("There are no Sales in this Year", {
                  "theme": "colored",
                  "type": "warning",
                  "autoClose": 1000,
                })
      loading_y.value = true;
      noData_y.value = true;
      console.log(noData_y.value);
    }

    console.log(list_salesy.value.length);
    for (let i = 0; i < list_salesy.value.length; i++)
    {
      //console.log("list_Advertisements[i]");
      //console.log(i);
      //console.log(list_sales.value[i].photo);
      //let active ;
      //let open ;
  
     
    
      let one_sale = { 
        id: list_salesy.value[i].id,
        daily_sale_date: list_salesy.value[i].daily_sale_date, 
        total_sales: parseInt(list_salesy.value[i].total_sales), 
        total_delivery_cost: parseInt(list_salesy.value[i].total_delivery_cost), 
        total_summation: parseInt(list_salesy.value[i].total_summation), 
      };


      yearly_Sales.value += one_sale.total_sales
      yearly_Delivery.value += one_sale.total_delivery_cost
      yearly_Total.value += one_sale.total_summation
      listy.value.push(one_sale);


      
  }
  } catch (error) {
    console.error(error);
    toast(" Try Again...", {
                  "theme": "colored",
                  "type": "warning",
                  "autoClose": 1000,
                })
  }
};





const fetchDatamonthly = async () => {
  try {
    const response = await axios.request(config2);
    list_salesm.value = response.data
    //console.log("list_Advertisements");
    if (list_salesm.value.length > 0) {
      loading_m.value = true;
    }
    if (list_salesm.value.length === 0) {
      toast("There are no Sales in this Month", {
                  "theme": "colored",
                  "type": "warning",
                  "autoClose": 1000,
                })
      loading_m.value = true;
      noData_m.value = true;
    }

    for (let i = 0; i < list_salesm.value.length; i++)
    {
      let one_sale = { 
        id: list_salesm.value[i].id,
        daily_sale_date: list_salesm.value[i].daily_sale_date, 
        total_sales: parseInt(list_salesm.value[i].total_sales), 
        total_delivery_cost: parseInt(list_salesm.value[i].total_delivery_cost), 
        total_summation: parseInt(list_salesm.value[i].total_summation), 
      };


      monthly_Sales.value += one_sale.total_sales
      monthly_Delivery.value += one_sale.total_delivery_cost
      monthly_Total.value += one_sale.total_summation
      listm.value.push(one_sale);

      
  }
  } catch (error) {
    console.error(error);
    toast("Try Again...", {
                  "theme": "colored",
                  "type": "warning",
                  "autoClose": 1000,
                })
  }
};







const fetchDataDaily = async () => {
  try {
    const response = await axios.request(config);
    list_sales.value = response.data
    if (list_sales.value.length > 0) {
      loading_d.value = true;
    }
    if (list_sales.value.length === 0) {
      toast("There are no Sales Today", {
                  "theme": "colored",
                  "type": "warning",
                  "autoClose": 1000,
                })
      loading_d.value = true;
      noData_d.value = true;
    }

    for (let i = 0; i < list_sales.value.length; i++)
    {

  
      let startIndex = list_sales.value[i].created_at.length - 17;
      const createTime = list_sales.value[i].created_at.slice(0, startIndex);
      startIndex = list_sales.value[i].updated_at.length - 17;
      const updateTime = list_sales.value[i].updated_at.slice(0, startIndex);
    
      let one_sale = { 
        id: list_sales.value[i].id,
        daily_sale_date: list_sales.value[i].daily_sale_date, 
        total_sales: parseInt(list_sales.value[i].total_sales), 
        total_delivery_cost: parseInt(list_sales.value[i].total_delivery_cost), 
        total_summation: parseInt(list_sales.value[i].total_summation), 
        owner_resturent_id: list_sales.value[i].owner_resturent_id,
        resturent_name: list_sales.value[i].resturent_name,
        created_at: createTime,
        updated_at: updateTime,
      };


      Daily_Sales.value += one_sale.total_sales
      Daily_Delivery.value += one_sale.total_delivery_cost
      Daily_Total.value += one_sale.total_summation
      list.value.push(one_sale);

      
  }
  } catch (error) {
    toast("Try Again...", {
                  "theme": "colored",
                  "type": "warning",
                  "autoClose": 1000,
                })
  }
};


const GetTotalSaleByMounthForSuperAdmin = async () =>{
  monthCollection.value = [];
  m1.value = 0;
m2.value = 0;
m3.value = 0;
m4.value = 0;
m5.value = 0;
m6.value = 0;
m7.value = 0;
m8.value = 0;
m9.value = 0;
m10.value = 0;
m11.value = 0;
m12.value = 0;
m13.value = 0;
m14.value = 0;
m15.value = 0;
m16.value = 0;
m17.value = 0;
m18.value = 0;
m19.value = 0;
m20.value = 0;
m21.value = 0;
m22.value = 0;
m23.value = 0;
m24.value = 0;
m25.value = 0;
m26.value = 0;
m27.value = 0;
m28.value = 0;
m29.value = 0;
m30.value = 0;
m31.value = 0;
  try {
    const axios = require('axios');
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL + 'GetTotalSaleByMounthForSuperAdmin/'+resturenID.value,
      headers: {
        'Authorization': 'Bearer '+userLog.token
       }
    };
    await axios.request(config)
    .then((response) => {
      for (const key in response.data) 
        monthCollection.value = [...monthCollection.value, response.data[key]];
        monthCollection.value.forEach((monthValue, index) => {
        

        if(monthValue != null){
        switch(index) {
          case 0:
            m1.value = monthValue;
            break;
          case 1:
            m2.value = monthValue;
            break;
          case 2:
            m3.value = monthValue;
            break;
          case 3:
            m4.value = monthValue;
            break;
          case 4:
            m5.value = monthValue;
            break;
          case 5:
            m6.value = monthValue;
            break;
          case 6:
            m7.value = monthValue;
            break;
          case 7:
            m8.value = monthValue;
            break;
          case 8:
            m9.value = monthValue;
            break;
          case 9:
            m10.value = monthValue;
            break;
          case 10:
            m11.value = monthValue;
            break;
          case 11:
            m12.value = monthValue;
            break;
          case 12:
            m13.value = monthValue;
            break;
            case 13:
            m14.value = monthValue;
            break;
            case 14:
            m15.value = monthValue;
            break;
            case 15:
            m16.value = monthValue;
            break;
            case 16:
            m17.value = monthValue;
            break;
            case 17:
            m18.value = monthValue;
            break;
            case 18:
            m19.value = monthValue;
            break;
            case 19:
            m20.value = monthValue;
            break;
            case 20:
            m21.value = monthValue;
            break;
            case 21:
            m22.value = monthValue;
            break;
            case 22:
            m23.value = monthValue;
            break;
            case 23:
            m24.value = monthValue;
            break;
            case 24:
            m25.value = monthValue;
            break;
            case 25:
            m26.value = monthValue;
            break;
            case 26:
            m27.value = monthValue;
            break;
            case 27:
            m28.value = monthValue;
            break;
            case 28:
            m29.value = monthValue;
            break;
            case 29:
            m30.value = monthValue;
            break;
            case 30:
            m31.value = monthValue;
            break;
        }
      }
      });
    })
    .catch(() => {
    });
  } catch (error) {
    toast("Try Again...", {
      "theme": "colored",
      "type": "warning",
      "autoClose": 1000,
    })
  }
}

const getTotalSalesByWeekSuperAdmin = async () => {
  weekCollection.value = [];
  weekD1.value = 0;
weekD2.value = 0;
weekD3.value = 0;
weekD4.value = 0;
weekD5.value = 0;
weekD6.value = 0;
weekD7.value = 0;
  try {
    const axios = require('axios');
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL + 'getTotalSalesByWeekSuperAdmin/'+resturenID.value,
      headers: { 
        'Authorization': 'Bearer '+userLog.token
      }
    };
    await axios.request(config)
    .then((response) => {
      for (const key in response.data) 
        weekCollection.value = [...weekCollection.value, response.data[key]];
      weekCollection.value.forEach((monthValue, index) => {

        if(monthValue != null){
        switch(index) {
          case 0:
            weekD1.value = monthValue;
            break;
          case 1:
          weekD2.value = monthValue;
            break;
          case 2:
          weekD3.value = monthValue;
            break;
          case 3:
          weekD4.value = monthValue;
            break;
          case 4:
          weekD5.value = monthValue;
            break;
          case 5:
          weekD6.value = monthValue;
            break;
          case 6:
          weekD7.value = monthValue;
            break;
          
        }
      }
      });

    })
    .catch(() => {
    });
  } catch (error) {
    toast("Try Again...", {
      "theme": "colored",
      "type": "warning",
      "autoClose": 1000,
    })
  }
};


const getTotalSalesByYearForSuperAdmin = async () => {
  yearCollection.value = [];
  Jan.value = 0;
Feb.value = 0;
Mar.value = 0;
Apr.value = 0;
May.value = 0;
Jun.value = 0;
Jul.value = 0;
Aug.value = 0;
Sep.value = 0;
Oct.value = 0;
Nov.value = 0;
Dec.value = 0;
  try {
    const axios = require('axios');
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL + 'getTotalSalesByYearForSuperAdmin/'+resturenID.value,
      headers: { 
        'Authorization': 'Bearer '+userLog.token
      }
    };
    await axios.request(config)
    .then((response) => {
      for (const key in response.data) 
        yearCollection.value = [...yearCollection.value, response.data[key]];
      yearCollection.value.forEach((monthValue, index) => {

        switch(index) {
          case 0:
            Jan.value = monthValue;
            break;
          case 1:
            Feb.value = monthValue;
            break;
          case 2:
            Mar.value = monthValue;
            break;
          case 3:
            Apr.value = monthValue;
            break;
          case 4:
            May.value = monthValue;
            break;
          case 5:
            Jun.value = monthValue;
            break;
          case 6:
            Jul.value = monthValue;
            break;
          case 7:
            Aug.value = monthValue;
            break;
          case 8:
            Sep.value = monthValue;
            break;
          case 9:
            Oct.value = monthValue;
            break;
          case 10:
            Nov.value = monthValue;
            break;
          case 11:
            Dec.value = monthValue;
            break;
        }
      });
    })
    .catch(() => {
    });
  } catch (error) {
    toast("Try Again...", {
      "theme": "colored",
      "type": "warning",
      "autoClose": 1000,
    })
  }
};


const GetTotalSaleBytowMounthForSuperAdmin = async () =>{
  towmonthCollection.value = [];
  mc1.value = 0;
mc2.value = 0;
mc3.value = 0;
mc4.value = 0;
mc5.value = 0;
mc6.value = 0;
mc7.value = 0;
mc8.value = 0;
mc9.value = 0;
mc10.value = 0;
mc11.value = 0;
mc12.value = 0;
mc13.value = 0;
mc14.value = 0;
mc15.value = 0;
mc16.value = 0;
mc17.value = 0;
mc18.value = 0;
mc19.value = 0;
mc20.value = 0;
mc21.value = 0;
mc22.value = 0;
mc23.value = 0;
mc24.value = 0;
mc25.value = 0;
mc26.value = 0;
mc27.value = 0;
mc28.value = 0;
mc29.value = 0;
mc30.value = 0;
mc31.value = 0;

mp1.value = 0;
mp2.value = 0;
mp3.value = 0;
mp4.value = 0;
mp5.value = 0;
mp6.value = 0;
mp7.value = 0;
mp8.value = 0;
mp9.value = 0;
mp10.value = 0;
mp11.value = 0;
mp12.value = 0;
mp13.value = 0;
mp14.value = 0;
mp15.value = 0;
mp16.value = 0;
mp17.value = 0;
mp18.value = 0;
mp19.value = 0;
mp20.value = 0;
mp21.value = 0;
mp22.value = 0;
mp23.value = 0;
mp24.value = 0;
mp25.value = 0;
mp26.value = 0;
mp27.value = 0;
mp28.value = 0;
mp29.value = 0;
mp30.value = 0;
mp31.value = 0;
  try {
    const axios = require('axios');
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BaseURL + 'getTotalSalesByMonthTWOForSuperAdmin/'+resturenID.value,
      headers: {
        'Authorization': 'Bearer '+userLog.token
       }
    };
    await axios.request(config)
    .then((response) => {
      for (const key in response.data) 
        towmonthCollection.value = [...towmonthCollection.value, response.data[key]];
        let i = 0 ;
        for (const key in towmonthCollection.value[0]) {
          if(towmonthCollection.value[0][key] != null){

          switch(i) {
            case 0:
              mc1.value = towmonthCollection.value[0][key];
              break;
            case 1:
              mc2.value = towmonthCollection.value[0][key];
              break;
            case 2:
              mc3.value = towmonthCollection.value[0][key];
              break;
            case 3:
              mc4.value = towmonthCollection.value[0][key];
              break;
            case 4:
              mc5.value = towmonthCollection.value[0][key];
              break;
            case 5:
              mc6.value = towmonthCollection.value[0][key];
              break;
            case 6:
              mc7.value = towmonthCollection.value[0][key];
              break;
            case 7:
              mc8.value = towmonthCollection.value[0][key];
              break;
            case 8:
              mc9.value = towmonthCollection.value[0][key];
              break;
            case 9:
              mc10.value = towmonthCollection.value[0][key];
              break;
            case 10:
              mc11.value = towmonthCollection.value[0][key];
              break;
            case 11:
              mc12.value = towmonthCollection.value[0][key];
              break;
            case 12:
              mc13.value = towmonthCollection.value[0][key];
              break;
              case 13:
              mc14.value = towmonthCollection.value[0][key];
              break;
              case 14:
              mc15.value = towmonthCollection.value[0][key];
              break;
              case 15:
              mc16.value = towmonthCollection.value[0][key];
              break;
              case 16:
              mc17.value = towmonthCollection.value[0][key];
              break;
              case 17:
              mc18.value = towmonthCollection.value[0][key];
              break;
              case 18:
              mc19.value = towmonthCollection.value[0][key];
              break;
              case 19:
              mc20.value = towmonthCollection.value[0][key];
              break;
              case 20:
              mc21.value = towmonthCollection.value[0][key];
              break;
              case 21:
              mc22.value = towmonthCollection.value[0][key];
              break;
              case 22:
              mc23.value = towmonthCollection.value[0][key];
              break;
              case 23:
              mc24.value = towmonthCollection.value[0][key];
              break;
              case 24:
              mc25.value = towmonthCollection.value[0][key];
              break;
              case 25:
              mc26.value = towmonthCollection.value[0][key];
              break;
              case 26:
              mc27.value = towmonthCollection.value[0][key];
              break;
              case 27:
              mc28.value = towmonthCollection.value[0][key];
              break;
              case 28:
              mc29.value = towmonthCollection.value[0][key];
              break;
              case 29:
              mc30.value = towmonthCollection.value[0][key];
              break;
              case 30:
              mc31.value = towmonthCollection.value[0][key];
              break;
          }
          }
          i = i+1;
        }
        for (const key in towmonthCollection.value[1]) {
            if(towmonthCollection.value[1][key] != null){
            switch(i) {
              case 0:
                mp1.value = towmonthCollection.value[1][key];
                break;
              case 1:
                mp2.value = towmonthCollection.value[1][key];
                break;
              case 2:
                mp3.value = towmonthCollection.value[1][key];
                break;
              case 3:
                mp4.value = towmonthCollection.value[1][key];
                break;
              case 4:
                mp5.value = towmonthCollection.value[1][key];
                break;
              case 5:
                mp6.value = towmonthCollection.value[1][key];
                break;
              case 6:
                mp7.value = towmonthCollection.value[1][key];
                break;
              case 7:
                mp8.value = towmonthCollection.value[1][key];
                break;
              case 8:
                mp9.value = towmonthCollection.value[1][key];
                break;
              case 9:
                mp10.value = towmonthCollection.value[1][key];
                break;
              case 10:
                mp11.value = towmonthCollection.value[1][key];
                break;
              case 11:
                mp12.value = towmonthCollection.value[1][key];
                break;
              case 12:
                mp13.value = towmonthCollection.value[1][key];
                break;
                case 13:
                mp14.value = towmonthCollection.value[1][key];
                break;
                case 14:
                mp15.value = towmonthCollection.value[1][key];
                break;
                case 15:
                mp16.value = towmonthCollection.value[1][key];
                break;
                case 16:
                mp17.value = towmonthCollection.value[1][key];
                break;
                case 17:
                mp18.value = towmonthCollection.value[1][key];
                break;
                case 18:
                mp19.value = towmonthCollection.value[1][key];
                break;
                case 19:
                mp20.value = towmonthCollection.value[1][key];
                break;
                case 20:
                mp21.value = towmonthCollection.value[1][key];
                break;
                case 21:
                mp22.value = towmonthCollection.value[1][key];
                break;
                case 22:
                mp23.value = towmonthCollection.value[1][key];
                break;
                case 23:
                mp24.value = towmonthCollection.value[1][key];
                break;
                case 24:
                mp25.value = towmonthCollection.value[1][key];
                break;
                case 25:
                mp26.value = towmonthCollection.value[1][key];
                break;
                case 26:
                mp27.value = towmonthCollection.value[1][key];
                break;
                case 27:
                mp28.value = towmonthCollection.value[1][key];
                break;
                case 28:
                mp29.value = towmonthCollection.value[1][key];
                break;
                case 29:
                mp30.value = towmonthCollection.value[1][key];
                break;
                case 30:
                mp31.value = towmonthCollection.value[1][key];
                break;
            }
          }
        }

    })
    .catch(() => {
      toast("Try Again...", {
        "theme": "colored",
        "type": "warning",
        "autoClose": 1000,
      })
    });
  } catch (error) {
    toast("Try Again...", {
                  "theme": "colored",
                  "type": "warning",
                  "autoClose": 1000,
                })
  }
}


let weekbuttonbool  = ref(true);
let monthbuttonbool  = ref(false);
let yearbuttonbool  = ref(false);
let towmonthbuttonbool  = ref(false);


const weekbuttonboolfunc = () => {
  weekbuttonbool.value = true;
  monthbuttonbool.value = false;
  yearbuttonbool.value = false;
  towmonthbuttonbool.value = false;

  weekDataBool.value = true;
  monthDataBool.value = false;
  yearDataBool.value  = false;
  towmonthDataBool.value = false;
}

const monthbuttonboolfunc = () => {
  weekbuttonbool.value = false;
  monthbuttonbool.value = true;
  yearbuttonbool.value = false;
  towmonthbuttonbool.value = false;

  weekDataBool.value = false;
  monthDataBool.value = true;
  yearDataBool.value  = false;
  towmonthDataBool.value = false;
}

const yearbuttonboolfunc = () => {
  weekbuttonbool.value = false;
  monthbuttonbool.value = false;
  yearbuttonbool.value = true;
  towmonthbuttonbool.value = false;

  weekDataBool.value = false;
  monthDataBool.value = false;
  yearDataBool.value  = true;
  towmonthDataBool.value = false;
}

const towmonthbuttonboolfunc = () => {
  weekbuttonbool.value = false;
  monthbuttonbool.value = false;
  yearbuttonbool.value = false;
  towmonthbuttonbool.value = true;

  weekDataBool.value = false;
  monthDataBool.value = false;
  yearDataBool.value  = false;
  towmonthDataBool.value = true;
}

let resturenID  = ref(1);
let we_get_data_now = ref(false);
const getIDResturent = async(id) => {
  we_get_data_now.value = false;
  resturenID.value = id;
  for (let i = 0; i < list_res.value.length; i++) {
    if(list_res.value[i].isSelected == true)
      list_res.value[i].isSelected = false;
    if(list_res.value[i].id == id)
      list_res.value[i].isSelected = true;
  }
  await getTotalSalesByYearForSuperAdmin();
  await GetTotalSaleByMounthForSuperAdmin();
  await getTotalSalesByWeekSuperAdmin();
  await GetTotalSaleBytowMounthForSuperAdmin();
  we_get_data_now.value = true;
};
onMounted(async() =>  {
  we_get_data_now.value = false;
  await getResturent();
  await getTotalSalesByYearForSuperAdmin();
  await GetTotalSaleByMounthForSuperAdmin();
  await getTotalSalesByWeekSuperAdmin();
  await GetTotalSaleBytowMounthForSuperAdmin();
  we_get_data_now.value = true;
  fetchDataDaily();
  fetchDatamonthly();
  fetchDatayearly();
  //imagine();
});
</script>


<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <mini-statistics
              title="Year's Sales"
              :value="yearly_Sales"
              description="<span
                class='text-sm font-weight-bolder text-warning'
                >SR</span>"
              :icon="{
                component: 'ni ni-shop',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <mini-statistics
              title="Year's Delivery"
              :value="yearly_Delivery"
              description="<span
                class='text-sm font-weight-bolder text-warning'
                >SR</span>"
              :icon="{
                component: 'ni ni-delivery-fast',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <mini-statistics
              title="Yearly Total"
              :value="yearly_Total"
              description="<span
                class='text-sm font-weight-bolder text-danger'
                >-2%</span> since last quarter"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
       
        
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <mini-statistics
              title="Month's Sales"
              :value="monthly_Sales"
              description="<span
                class='text-sm font-weight-bolder text-warning'
                >SR</span>"
              :icon="{
                component: 'ni ni-shop',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <mini-statistics
              title="Month's Delivery"
              :value="monthly_Delivery"
              description="<span
                class='text-sm font-weight-bolder text-warning'
                >SR</span>"
              :icon="{
                component: 'ni ni-delivery-fast',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <mini-statistics
              title="Month Total"
              :value="monthly_Total"
              description="<span
                class='text-sm font-weight-bolder text-danger'
                >-2%</span> since last quarter"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
        
        <hr>
        <div class=" container-side-by-side">
          <div class="col-2">
            <div v-for="resturent in list_res" :key="resturent.id">
              
            </div>

            <div class="table-responsive p-0 section-container">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="list_res.length > 0">
                    <tr v-for="restaurant in list_res" :key="restaurant.id">
                      <td>
                        <div class="d-flex px-2 py-1" style="cursor: pointer;">
                          <div class="d-flex flex-column justify-content-center" @click="getIDResturent(restaurant.id)">
                            
                            <h6 v-if="restaurant.isSelected" class="mb-0 text-sm text-white bg-warning">{{ restaurant.resturent_name }}</h6>
                            <h6 v-else class="mb-0 text-sm text-warning">{{ restaurant.resturent_name }}</h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <div class="loader"></div>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
       
        
        <div class="col-lg-10" style="padding-left: 5%;">
        <div class="">  
          <nav>
            <ul class="container-side-by-side">
              <div style="padding-right: 2%;" >
                <button class="badge badge-lg" :class="[weekbuttonbool ? 'custom-bg1 bg-gradient-warning' : 'custom-bg2 bg-gradient-white text-warning']"  @click="weekbuttonboolfunc">
                  Weekly
                  <i class="pi pi-bullseye"></i>
                </button>
              </div>
              <div style="padding-right: 2%;" >
                <button class="badge badge-lg" :class="[monthbuttonbool ? 'custom-bg1 bg-gradient-warning' : 'custom-bg2 bg-gradient-white text-warning']"  @click="monthbuttonboolfunc">
                  Monthly
                  <i class="pi pi-bullseye"></i>
                </button>
              </div>
              <div style="padding-right: 2%;" >
                <button class="badge badge-lg" :class="[yearbuttonbool ? 'custom-bg1 bg-gradient-warning' : 'custom-bg2 bg-gradient-white text-warning']"  @click="yearbuttonboolfunc">
                  Yearly
                  <i class="pi pi-bullseye"></i>
                </button>
              </div>
              <div style="padding-right: 2%;" >
                <button class="badge badge-lg" :class="[towmonthbuttonbool ? 'custom-bg1 bg-gradient-warning' : 'custom-bg2 bg-gradient-white text-warning']"  @click="towmonthbuttonboolfunc">
                  compare tow Month
                  <i class="pi pi-bullseye"></i>
                </button>
              </div>
            </ul>
          </nav>
        </div>
       
        <div class="row" v-if="towmonthDataBool && we_get_data_now">
          <div class="col-lg-12 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart
              v-if="towmonthDataBool"
                id="chart-line"
                :hight="250"
                title="Resturant Sales cuurently month and last month"
                :chart="{
                  labels: [
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                    '17',
                    '18',
                    '19',
                    '20',
                    '21',
                    '22',
                    '23',
                    '24',
                    '25',
                    '26',
                    '27',
                    '28',
                    '29',
                    '30',
                    '31',
                  ],
                  datasets: [
                    {
                      label: 'current month sales',
                      data: [mc1,mc2,mc3,mc4,mc5,mc6,mc7,mc8,mc9,mc10,mc12,mc13,mc14,mc15,mc16,mc17,mc18,mc19,mc20,mc21,mc22,mc23,mc24,mc25,mc26,mc27,mc28,mc29,mc30,mc31],
                    },
                    {
                      label: 'previous month sales',
                      data: [mp1,mp2,mp3,mp4,mp5,mp6,mp7,mp8,mp9,mp10,mp12,mp13,mp14,mp15,mp16,mp17,mp18,mp19,mp20,mp21,mp22,mp23,mp24,mp25,mp26,mp27,mp28,mp29,mp30,mp31],
                    },
                  ],
                }"              />
            </div>
          </div>
        </div>
        <div class="row" v-if="monthDataBool && we_get_data_now">
          <div class="col-lg-12 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart
              v-if="monthDataBool"
                id="chart-line"
                title="Resturant Sales monthly"
                :hight="250"
                :chart="{
                  labels: [
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                    '17',
                    '18',
                    '19',
                    '20',
                    '21',
                    '22',
                    '23',
                    '24',
                    '25',
                    '26',
                    '27',
                    '28',
                    '29',
                    '30',
                    '31',

                  ],
                  datasets: [
                    {
                      label: 'Total Sales',
                      data: [m1,m2,m3,m4,m5,m6,m7,m8,m9,m10,m12,m13,m14,m15,m16,m17,m18,m19,m20,m21,m22,m23,m24,m25,m26,m27,m28,m29,m30,m31],
                    },
                  ],
                }"              />
            </div>
          </div>
        </div>
        <div class="row" v-if="weekDataBool && we_get_data_now">
          <div class="col-lg-12 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart
                id="chart-line"
                title="Resturant Sales weekly "
                :hight="250"
                :chart="{
                  labels: [
                    'Sunday',
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                  ],
                  datasets: [
                    {
                      label: 'Total Sales',
                      data: [weekD1, weekD2, weekD3, weekD4, weekD5, weekD6, weekD7],
                    },
                  ],
                }"              />
            </div>
          </div>
        </div>
        <div class="row" v-if="yearDataBool && we_get_data_now">
          <div class="col-lg-12 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart
                id="chart-line"
                title="Resturant Sales Yearly "
                :hight="250"
                :chart="{
                  labels: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ],
                  datasets: [
                    {
                      label: 'Total Sales',
                      data: [Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec],
                    },
                  ],
                }"              />
            </div>
          </div>
        </div>
      </div>
      </div>
        <hr>

        <div class="row bg-warning" style="padding-top:2%">
          <div class="col-lg-4 col-md-4 col-12">
            <mini-statistics
              title="ALL Sales"
              :value="Daily_Sales"
              description="<span
                class='text-sm font-weight-bolder text-warning'
                >SR</span>"
              :icon="{
                component: 'ni ni-shop',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics
              title="ALL Delivery"
              :value="Daily_Delivery"
              description="<span
                class='text-sm font-weight-bolder text-warning'
                >SR</span>"
              :icon="{
                component: 'ni ni-delivery-fast',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics
              title="ALL Total"
              :value="Daily_Total"
              description="<span
                class='text-sm font-weight-bolder text-danger'
                >-2%</span> since last quarter"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
        <hr>


      </div>
    </div>
  </div>
</template>


<style>

h4{
  text-align: center;
  font-family: Sevillana;
  text-shadow: #ccc;
}

hr {
  margin: 20px 0; /* Add top and bottom margins */
  border: 1px solid #ccc; /* Set border style and color */
  height: 2px; /* Adjust line thickness */
}

img {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}

.container-side-by-side { 
	display: flex; 
	flex-direction: row; 
	flex-wrap: wrap; 
} 


.custom-bg1 {
  border: 2px solid #ff8f07;
  float: right;
  cursor: pointer; /* تغيير المؤشر عند التمرير على الزر */
  transition: transform 0.2s ease-in-out; /* إضافة حركة بسيطة */
}


.custom-bg2 {
  color: #faebbc;
  float: right;
  border: none; /* إزالة الحواف */
  cursor: pointer; /* تغيير المؤشر عند التمرير على الزر */
}


.custom-bg1:hover {
  transform: scale(1.05); /* تكبير الزر قليلاً عند التمرير عليه */
}

.section-container {
  max-height: 400px; /* ارتفاع ثابت للعنصر الذي يحتوي على الجدول */
  min-height:100px;
  overflow-y: auto; /* تمكين التمرير العمودي */
  border: 2px solid #ff8c00; /* حدود الاختياري للعنصر */
  border-radius: 10px;
  margin: 1%;
}
</style>