
<script setup>
import {BaseURL, userLog} from '../DATA/login'
import { ref, /*onMounted,*/ onMounted } from 'vue'



let list = ref([]);
const list_users__ = ref([]);

const axios = require('axios');
let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: BaseURL+'getCustomers',
  headers: { 
    'Authorization': 'Bearer '+userLog.token
  }
};




const fetchData = async () => {
  try {
    const response = await axios.request(config);
    list_users__.value = response.data;
    console.log(list_users__.value);
    for (let i = 0; i< list_users__.value.length; i++)
    {
  
      let active ;
      let open ;
      if(list_users__.value[i].status == "Active"){
        active = "badge badge-sm bg-gradient-success";
        open = "Active";
      }else{
        active = "badge badge-sm bg-gradient-secondary";
        open = "blocked";
      }
    
      let one_user = { 
        id: list_users__.value[i].id,
        name: list_users__.value[i].name, 
        email: list_users__.value[i].email, 
        phone: list_users__.value[i].phone, 
        cancel_count: list_users__.value[i].cancel_count, 
        points:list_users__.value[i].points, 
        blocked_until: list_users__.value[i].blocked_until,
        status: list_users__.value[i].status,
        roleID: list_users__.value[i].pivot.role_id,
        active: active,
        open: open,
      };
      list.value.push(one_user);
  

    
  }



  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  fetchData();
});

</script>



<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Users in System</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                NAME
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                PHONE
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                POINTS
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <template v-if="list.length > 0">
              <tr v-for="USER in list" :key = USER.id>
                  <td>
                    <div class="d-flex px-2 py-1">
                      
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ USER.name }}</h6>
                        <p class="text-xs text-secondary mb-0">
                          {{USER.email}}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p class="text-xs font-weight-bold mb-0">{{USER.phone}}</p>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <span :class= USER.active>{{ USER.open }}</span>
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{ USER.points }}</span
                    >
                  </td>
                  <!--<td class="align-middle">
                    <a
                      href="javascript:;"
                      class="text-secondary font-weight-bold text-xs"
                      data-toggle="tooltip"
                      data-original-title="Edit user"
                      >Edit</a
                    >
                  </td>-->
                </tr>
            </template>
            <template v-else>
              <div class="loader"></div>

            </template>
                
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<style>


.loader {
  position: absolute;
   top: 50%;
    left: 50%;
  z-index: 1;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(251, 99, 64);
  border-bottom: 4px solid rgb(251, 99, 64);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>