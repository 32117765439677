<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";

import {BaseURL, userLog, imageURL } from './DATA/login'

import { ref, /*onMounted,*/ onMounted } from 'vue'

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

let loading = ref(false);
let noData = ref(false);
let list = ref([]);
const list_Advertisements = ref([]);

const axios = require('axios');
let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: BaseURL + 'allAdvertisements',
  headers: { 
    'Authorization': 'Bearer '+userLog.token
  }
};



const fetchData = async () => {
  try {
    const response = await axios.request(config);
    list_Advertisements.value = response.data[0];
    if (list_Advertisements.value.length > 0) {
      loading.value = true;
    }
    if (list_Advertisements.value.length === 0) {
      toast("There are no pending Advertisements", {
                  "theme": "colored",
                  "type": "warning",
                  "autoClose": 1000,
                })
      loading.value = false;
      noData.value = true;
    }

    for (let i = 0; i < list_Advertisements.value.length; i++)
    {
      
      let startIndex = list_Advertisements.value[i].created_at.length - 17;
      const createTime = list_Advertisements.value[i].created_at.slice(0, startIndex);
      startIndex = list_Advertisements.value[i].updated_at.length - 17;
      const updateTime = list_Advertisements.value[i].updated_at.slice(0, startIndex);

      let idMealOrOffer = ref('');
      let ismeal = ref(false);
      if(list_Advertisements.value[i].meal_id != null){
      idMealOrOffer.value = list_Advertisements.value[i].meal_id;
      ismeal.value = true;
    }
      else{
      idMealOrOffer.value = list_Advertisements.value[i].offer_id;
      ismeal.value = false;
      }
    
      let one_Advertisement = { 
        id: list_Advertisements.value[i].id,
        name: list_Advertisements.value[i].name, 
        photo: list_Advertisements.value[i].photo, 
        type: list_Advertisements.value[i].type, 
        meal_id: list_Advertisements.value[i].meal_id, 
        offer_id:list_Advertisements.value[i].offer_id, 
        owner_resturent_id: list_Advertisements.value[i].owner_resturent_id,
        Agree: list_Advertisements.value[i].Agree,
        created_at: createTime,
        updated_at: updateTime,
        URL: imageURL+list_Advertisements.value[i].photo,
        idMealOrOffer:idMealOrOffer.value,
        ismeal:ismeal.value
      };
      list.value.push(one_Advertisement);
      
  }





  } catch (error) {
    toast("Error when get Advertisements:  Try Again...", {
      "theme": "colored",
      "type": "warning",
      "autoClose": 1000,
    })
  }
};

onMounted(() => {
  fetchData();
});






</script>
<template>
  <div class="py-1 container-fluid">
    <div class="row">
      <div class="col-lg-20">
        <div v-if="loading">
          <div v-if="noData">
          <img src="../assets/img/admin/sign.jpg" alt="No Data">
          </div>
        <div class="row" v-else>
          <div class="col-xl-12 col-lg-15 col-md-24 mx-auto" v-for="advertisement in list" :key = advertisement.id>
            <mini-statistics-card
              :title ="advertisement.name"
              :id ="advertisement.id"
              :ismeal ="advertisement.ismeal"
              :idMealOrOffer="advertisement.idMealOrOffer"
              :advertisement_type="advertisement.type"
              :description="advertisement.created_at"
              :imageURL="advertisement.URL"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                // shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="loader"></div>
      </div>
      </div>
    </div>
  
    </div>
</template>

<style>


.loader {
  position: absolute;
   top: 50%;
    left: 50%;
  z-index: 1;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid rgb(251, 99, 64);
  border-bottom: 4px solid rgb(251, 99, 64);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

img {
  max-width: 60vw;
}
</style>