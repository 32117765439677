<script>

import {BaseURL, userLog/*,
  get_list_advertisement_to_not_get_detail_again,
  set_list_advertisement_to_not_get_detail_again*/
} from '../../views/DATA/login'


import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';








export default {
  data() {
    return {
      showaccept: false,
      showreject: false,
      show: true,
      detaile:[],
    };
  },
  props: {
  rowReverse: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
 
  },
  id: {
    type: Number,
    required: true,
},
advertisement_type: {
    type: Number,
    required: true,
},
  description: {
    type: String,
    default: "",
  },
  idMealOrOffer: {
    type: String,
    default: "",
  },
  ismeal: {
    type: Boolean,
  },
  imageURL: {
    type: String,
    default: "",
  },
  percentage: {
    type: [Object, String],
    value: {
      type: String,
    },
    color: {
      type: String,
    },
    default: () => ({
      color: "success",
    }),
  },
  icon: {
    type: [String, Object],
    component: {
      type: String,
    },
    background: {
      type: String,
    },
    shape: {
      type: String,
    },
    default: () => ({
      background: "bg-white",
      shape: " border-radius-md",
    }),
  },
  classContent: {
    type: String,
    default: "",
  },
},
  methods: {
    async getDetail(){
      if(this.ismeal){
        const axios = require('axios');
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: BaseURL + 'showDetailMeal/'+ this.idMealOrOffer,
          headers: { 
            'Authorization': 'Bearer '+userLog.token
          }
        };
        axios.request(config)
        .then((response) => {
          console.log(response.data);
          let one_detaile = {
            id: response.data.id,
            name:response.data.name,
            price:response.data.price
          };
          this.detaile.push(one_detaile);
        })
        .catch((error) => {
          console.log(error);
        });

      }
      else{
        const axios = require('axios');

        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: BaseURL + 'detailOfferPackage/'+ this.idMealOrOffer,
          headers: { 
            'Authorization': 'Bearer '+userLog.token
          }
        };

        axios.request(config)
        .then((response) => {
          
          for(let i=0; i< response.data.length; i++){
            let one_detaile = {
            id: response.data[i].id,
            name:response.data[i].name,
            price:response.data[i].price
          };
          this.detaile.push(one_detaile);
          console.log(one_detaile);
          }
          
        })
        .catch((error) => {
          console.log(error);
        });

      }
    },
    async reject(id){
    const id_ = id

    const axios = require('axios');
    let config2 = {
    method: 'post',
    maxBodyLength: Infinity,
    url: BaseURL + 'Decline_to_advertise/'+ id_ ,
    headers: { 
      'Authorization': 'Bearer '+userLog.token
        }
      };
    await axios.request(config2)
    .then((response) => {
      console.log(response);
      toast("REJECT: "+response.data.message, {
                    "theme": "colored",
                    "type": "success",
                    "position": "top-left",
                    "autoClose": 1000,
                  })
                  this.show = false;
                  this.showreject = true
      }).catch((err) => {
        toast("NOT REJECT:  "+err.response.statusText, {
                    "theme": "colored",
                    "type": "error",
                    "position": "top-left",
                    "autoClose": 1000,
                  });
                });
  
},


async accept(id){
  const id_ = id
  console.log("accepting")
  console.log(id_)
  console.log(id)
const axios = require('axios');
  let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: BaseURL + 'AgreeAdvertisement/'+ id_ ,
  headers: { 
    'Authorization': 'Bearer '+userLog.token
  }
};
await axios.request(config)
    .then((response) => {
      console.log(response);
      toast("ACCEPT: "+response.data.message, {
                    "theme": "colored",
                    "type": "success",
                    "position": "top-left",
                    "autoClose": 1000,
                  })
                  this.show = false;
                  this.showaccept = true;
      }).catch((err) => {
        toast("NOT ACCEPT:  "+err.response.statusText, {
                    "theme": "colored",
                    "type": "error",
                    "position": "top-left",
                    "autoClose": 1000,
                  });
                });
            }
  },
  created() {
    //this.getDetail()
  },


}


</script>
<template>
  <div class="mb-3 card">
    <div class="p-3 card-body">
      <div
        class="d-flex"
        :class="rowReverse ? '' : 'flex-row-reverse justify-content-between'"
      >

      <div class="me-4">
          <div class="d-flex mt-4" v-if="this.show">
            <div class="ms-4">
              <button class="badge badge-lg bg-gradient-success button" @click="accept(id)">Accept</button>
            </div>
            <div class="ms-4">
              <button class="badge badge-lg bg-gradient-danger button2" @click="reject(id)">Reject</button>
            </div>
          </div>
          <div v-else>
            <div class="me-4">
              <img v-if="showaccept" src="@/assets/img/admin/accepted.png" width="50" height="50"
              class="avatar avatar-xxl rounded-circle me-2" alt="Accept" /> 
              <img v-else src="@/assets/img/admin/rejected.png" width="50" height="50"
              class="avatar avatar-xxl rounded-circle me-2" alt="reject" /> 
            </div>
          </div>
        </div>

        <div 
        class="d-flex"
        :class="rowReverse ? '' : 'flex-row-reverse justify-content-between'"
      >
          <div :class="classContent">
            <div class="numbers">
              
              <h5 class="text-uppercase font-weight-bold mb-0 font-weight-bolder">
                {{  title  }}
                
                <span
                  v-if="percentage && typeof percentage === 'string'"
                  class="text-sm font-weight-bolder"
                >
                  {{ percentage }}
                </span>
                <span
                  v-if="percentage && typeof percentage === 'object'"
                  :class="`text-sm font-weight-bolder text-${percentage.color}`"
                >
                  {{ percentage.value }}
                </span>
              </h5>
              <p v-for="meal in detaile" :key="meal.id" class="mb-0 text-sm font-weight-bold">
                {{ meal.name }}   | <span
                class='text-sm font-weight-bolder text-danger'
                >{{ meal.price }}</span>
              </p>
              <!--  eslint-disable-next-line vue/no-v-html -->
              <p v-if="description" class="mt-2 mb-0" >
                <span
                class='text-sm font-weight-bolder text-danger'
                >{{ advertisement_type }}</span> | {{ description }}
              </p>
            </div>
          </div>
          <div class="me-4">
            <img :src="imageURL" width="50" height="50"
            class="avatar avatar-xxl rounded-circle me-2" alt="Category" /> 
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<style>
.button {
  padding: 15px 25px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #aff1ce;
}
.button:hover {background-color: #3e8e41}

.button:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}


.button2 {
  padding: 15px 25px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #f1afaf;
}

.button2:hover {background-color: #ee7878}

.button2:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
</style>