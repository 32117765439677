<script setup>
//import { onBeforeUnmount, onBeforeMount } from "vue";
//import { useStore } from "vuex";

// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";


import { /*computed,*/ ref } from "vue";
import { BaseURL, userLog, /*imageURL*/} from './DATA/login'

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';





const name = ref("");
const email = ref("");
const password = ref("");
const phone = ref("");
const password_confirmation = ref("");

const addbool = ref(false);



const addMessege = ref("");

let errorList = []
//const addbool = ref(false);

function convertJsonErrorsToList(jsonString) {
  try {
    // Parse the JSON string to avoid potential errors
    const jsonData = JSON.parse(jsonString);

    // Create an empty list to store the error messages
    const errorList = [];

    // Iterate through each key-value pair in the parsed JSON object
    for (const [key, value] of Object.entries(jsonData)) {
      // Extract the error message (assuming the first element in the value array)
      const errorMessage = value[0];

      // Create a formatted error message string
      const formattedError = `${key}: ${errorMessage}`;

      // Append the formatted error message to the list
      errorList.push(formattedError);
    }

    return errorList;
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return []; // Return an empty list if parsing fails
  }
}


//const addbool = ref(false);



const addFunction =  () => {
const axios = require('axios');
const FormData = require('form-data');
let data = new FormData();
data.append('name', name.value);
data.append('email', email.value);
data.append('password', password.value);
data.append('phone', phone.value);
data.append('password_confirmation', password_confirmation.value);





console.log(data);
let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: BaseURL+'AddAccountDeliveryManger',
  headers: { 
    'Authorization': 'Bearer '+userLog.token, 
 
  },
  data : data
};

axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
  if(response.statusCode == 201){
    toast(response.data.message, {
                  "theme": "colored",
                  "type": "success",
                  "position": "top-left",
                  "autoClose": 1000,
                })
  }
})
.catch((error) => {
  console.log(error);
  addMessege.value  = "Don't Add Manager Again :   "+error+" :   "
  toast(addMessege.value, {
    "theme": "colored",
    "type": "error",
    "autoClose": 1000,
  })
  errorList = convertJsonErrorsToList(error.response.data);
  for (const element of errorList) {
    console.log(element);
    toast(element, {
    "theme": "colored",
    "position": "bottom-right",
    "type": "warning",
    "autoClose": 4000,
    "transition": "flip"
  })
  }
});
};

</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
       :style="{backgroundImage: 'url(\'' + require('@/assets/img/admin/addManager.jpg') + '\')'}"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h2 class="text-white mb-2 mt-5">ADD NEW Delivary Manager  !</h2>
            <p class="text-lead text-white">
            Create a new Delivary Manager account for new employee in the System.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="!addbool">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-8 col-lg-10 col-md-14 mx-auto">
          <div class="card z-index-0">
            <div class="card-body">
                <argon-input
                  id="name"
                  type="text"
                  placeholder="Name"
                  aria-label="Name"
                  v-model="name"
                />
               
                <argon-input
                  id="email"
                  type="email"
                  placeholder="Email"
                  aria-label="Email"
                  v-model="email"
                />
                <argon-input
                  id="phone"
                  type="number"
                  placeholder="Phone"
                  aria-label="Phone"
                  v-model="phone"
                />
                
                <argon-input
                  id="password"
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  v-model="password"
                />

                <argon-input
                  id="password_confirmation"
                  type="password"
                  placeholder="Password Confirmation"
                  aria-label="Password Confirmation"
                  v-model="password_confirmation"
                />
                <!-- <argon-checkbox checked>
                  <label class="form-check-label" for="flexCheckDefault">
                    I agree the
                    <a href="javascript:;" class="text-dark font-weight-bolder"
                      >Terms and Conditions</a
                    >
                  </label>
                </argon-checkbox> -->
                <div class="text-center"  @click="addFunction">
                  <argon-button
                    fullWidth
                    color="warning"
                    variant="gradient"
                    class="my-4 mb-2"
                    >Add Delivary Manager</argon-button
                  >
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="check-mark-container">
  <div class="check-mark"></div>
</div>
    </div>
  </main>
  <app-footer />
</template>

<style>
.check-mark-container {
  position: fixed;
  bottom: 30%;
  right: 50%;
  display: inline-block;
}
.check-mark {
  width: 20px;
  height: 20px;
  background-color: rgb(148, 175, 162);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: checkmark-rotate 1s linear infinite;
}

@keyframes checkmark-rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.check-mark.active {
  background-color: green;
  animation: checkmark-grow 0.5s ease-in-out;
}

@keyframes checkmark-grow {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

.check-mark.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: green;
  animation: checkmark-rotate 1s linear infinite;
  opacity: 0.5;
}
</style>
