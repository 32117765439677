
<script setup>

import { computed } from "vue";
import { useStore } from "vuex";
import Configurator from "@/examples/Configurator.vue";
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
//import AppFooter from "@/examples/Footer.vue";
import Signin from "./views/Signin.vue";
import { set_fcm } from './views/DATA/login'


import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: 'AIzaSyBwXHl16oTlQM4JETamUkPO6SJwA51uxUM',
  authDomain: 'deliveryapp-16e35.firebaseapp.com',
  projectId: 'deliveryapp-16e35',
  storageBucket: 'deliveryapp-16e35.appspot.com',
  messagingSenderId: '445752029618',
  appId: '1:445752029618:web:c764f00308cbf3e297b4ab',
  measurementId: 'G-JRJS412TKG',
};
console.log('go to notifaction in app.vue');
const firebaseApp = initializeApp(firebaseConfig);
console.log('go to notifaction in app.vue2');
  const messaging = getMessaging(firebaseApp);
  console.log('go to notifaction in app.vue3');
  // Request permission to receive notifications
  Notification.requestPermission().then(function(permission) {
    console.log('go to notifaction in app.vue4');
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Get FCM token
      if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
      getToken(messaging, { vapidKey: 'BClQZ5zAvTfR3YazGM4JP4AMj1NW_4_hZIYsSrb7L0QCtwJA4UeZatBNY_sYmxG-yXlWCVYybDzAYNmbisxgMmU' , serviceWorkerRegistration: registration }).then((currentToken) => {
        if (currentToken) {
          console.log('FCM Token:', currentToken);
          // Send the token to your server or save it in the localStorage
          set_fcm(currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}
    } else {
      console.log('Unable to get permission to notify.');
    }
  })
  

const store = useStore();
const isNavFixed = computed(() => store.state.isNavFixed);
const darkMode = computed(() => store.state.darkMode);
const isAbsolute = computed(() => store.state.isAbsolute);
const showSidenav = computed(() => store.state.showSidenav);
const layout = computed(() => store.state.layout);
const showNavbar = computed(() => store.state.showNavbar);
//const showFooter = computed(() => store.state.showFooter);
const showConfig = computed(() => store.state.showConfig);
const hideConfigButton = computed(() => store.state.hideConfigButton);
const toggleConfigurator = () => store.commit("toggleConfigurator");

const navClasses = computed(() => {
  return {
    "position-sticky bg-white left-auto top-2 z-index-sticky":
      isNavFixed.value && !darkMode.value,
    "position-sticky bg-default left-auto top-2 z-index-sticky":
      isNavFixed.value && darkMode.value,
    "position-absolute px-4 mx-0 w-100 z-index-2": isAbsolute.value,
    "px-0 mx-4": !isAbsolute.value,
  };
});


let loginBool_ = computed(() => store.state.login);




</script>



<template>
<div>
  <notifications/>

  <div v-if="loginBool_ == true">
    <div
      v-show="layout === 'landing'"
      class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
    >
    </div>

    <sidenav v-if="showSidenav" />

    <main
      class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    >
      <!-- nav -->

      <navbar :class="[navClasses]" v-if="showNavbar" />

      <router-view />

      <!--<app-footer v-show="showFooter" />-->

      <configurator
        :toggle="toggleConfigurator"
        :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
      />
    </main>
  </div>

<div v-else>
<Signin/>

</div>
</div>
</template>
