<script setup>

import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { ref } from "vue";
import {UserClass, save, BaseURL, saveLoginBool, get_fcm} from './DATA/login';
//import image from '../assets/img/admin/sign2.jpg'




const store = useStore();
const body = document.getElementsByTagName("body")[0];




onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

//const login_image =  imageURL + "dynamic%20Images/loginBackgroundAdmin.jpg"
const email = ref("");
const password = ref("");



const loginbool = ref(false);

const is_not_logging = ref(false);
const errorMessege = ref("");



async function getData(LogData) {
  is_not_logging.value = false;
          try {
            const axios = require('axios');
            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: BaseURL+'login',
              headers: { 
              },
              data : LogData
            };
             axios.request(config)
              .then(response => {
                console.log(response)
                if(response.status == 200){
                  console.log(response.status);
                  console.log(response.data.token);
                  let userLog = new UserClass(response.data.token,
                    response.data.user[0].blocked_until,
                    response.data.user[0].cancel_count,
                    response.data.user[0].created_at,
                    response.data.user[0].email,
                    response.data.user[0].email_verified_at,
                    response.data.user[0].id,
                    response.data.user[0].name,
                    response.data.user[0].phone,
                    response.data.user[0].status,
                    response.data.user[0].updated_at);
                  loginbool.value = true;
                  saveLoginBool(loginbool.value);

                  store.state.login = true;
                  const massege = "Wellcome " +response.data.user[0].name
                  toast(massege, {
                  "autoClose": 2000,
                });
                let fcm = get_fcm();
                console.log("fcm in sign");
                console.log(fcm);
                const FormData = require('form-data');
          let data = new FormData();
          data.append('fcm_token', fcm);

          let config_fcm = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BaseURL+'saveFcmToken',
            headers: { 
              'Authorization': 'Bearer '+userLog.token, 
            },
            data : data
          };

          axios.request(config_fcm)
          .then((response) => {
            console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
                save(userLog);
              }
              else{
                is_not_logging.value = true;
                console.log("User is not logged");
                console.log(is_not_logging);
                toast("Error in Login", {
                  "theme": "colored",
                  "type": "error",
                  "position": "top-left",
                  "autoClose": 3000,
                })
              }
              })
              .catch(err => {
                console.log(err)
                console.log(err.message)
                is_not_logging.value = true;
                errorMessege.value  = err.message
                toast(errorMessege.value, {
                  "theme": "colored",
                  "type": "error",
                  "position": "top-left",
                  "autoClose": 3000,
                })
              });
          }
          catch (err) {
              console.error(err);
          }
      }



const loginFunction =  () => {
  try{
    const FormData = require('form-data');
    let LogData = new FormData();
    LogData.append('email', email.value);
    LogData.append('password', password.value);
    getData(LogData)
      }catch(err) {
      console.error(err);
  }
}




var i = 0;
var txt = 'Manage your restaurants and Delivary System here';
var speed = 50;


let wellcome = ref("");


function typeWriter() {
  if (i < txt.length) {
    wellcome.value += txt.charAt(i);
    i++;
    setTimeout(typeWriter, speed);
  }
}

typeWriter();


</script>




<template>
  <div>





  <div>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder text-warning">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  
                    <div class="mb-3">
                      <argon-input
                        id="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        size="lg"
                        v-model="email"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                        v-model="password"
                      />
                    </div>

                    
                    


                    <router-link to="/DashboardArt">
                      <div class="text-center" @click="loginFunction">
                        <argon-button
                          class="mt-4"
                          variant="gradient"
                          color="warning"
                          fullWidth
                          size="lg"
                          >Sign in</argon-button
                        >
                      </div>
                    </router-link>
                    
                  
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-warning h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                :style="{
                  backgroundImage: 'url(\'' + require('@/assets/img/admin/log3.jpg') + '\')',
                  backgroundSize: 'contain',
                  }"
              >
                <span class="mask bg-gradient-warning opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  Welcome Super Admin
                </h4>
                <p class="text-white position-relative" id="wellcome">
                  {{ wellcome }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>

</div>
</template>


<style>
img {
    width: 100%; /* عرض الصورة حسب الحاجة */
    height: auto; /* الحفاظ على نسبة العرض إلى الارتفاع */
    border-radius: 50% / 30%; /* يجعل الشكل بيضاوي أفقي */
    display: block; /* لضبط الحواف بشكل صحيح */
    margin: 0 auto; /* لتمركز الصورة إذا كانت داخل عنصر محاذي بالوسط */
}

/* لتطبيق التدرج والتلاشي التدريجي للحواف */
img {
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 0 30px rgba(96, 116, 120, 0.5); /* لإضافة التدرج الداخلي */
}

/* لإضافة تأثير التلاشي التدريجي */
img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50% / 30%; /* مطابقة الشكل البيضاوي */
    background: linear-gradient(transparent, rgba(255, 255, 255, 0.5)); /* لتدرج التلاشي */
    pointer-events: none; /* للسماح بالنقر من خلال التأثير */
}
</style>
